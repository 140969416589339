import * as React from "react";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import SEO from '../components/SEO'

const LocalServices = () => {
  const { allStrapiServiceAreaRank, getBlogged, localTierPlaceHolderImage} =
    useStaticQuery(graphql`
      query GetDirectoryImage {
        localTierPlaceHolderImage: file(relativePath: { eq: "lt_main.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        getBlogged: file(relativePath: { eq: "wifi-small-business.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 800
              height: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        } 
        allStrapiServiceAreaRank(sort: {fields: service___Name}) {
          edges {
            node {
              id
              matrixcontent
              city {
                id
                Name
                Slug
              }
              service {
                id
                Name
                Slug
                heroimage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 160, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);
  const getBloggedImageData = getImage(getBlogged)

  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"Blog"}
        description={"LocalTier Blog"}
      />
      <GatsbyImage
        image={getBloggedImageData}
        className="h-64 md:h-96 w-full object-cover"
      />

      <section className="bg-white">
        <div className="max-w-7xl mx-auto px-4 py-5 sm:px-6 sm:py-6 lg:px-8">
          <div className="py-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-6">
              LocalTier Service Directory</h1>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                {allStrapiServiceAreaRank.edges.map(({ node: post }, i) => {
                  const heroImage = getImage(post.service && post.service.heroimage && post.service.heroimage.localFile);
                  const placeHolderImageData = getImage(localTierPlaceHolderImage)



                  return (
                    <div
                      key={post.Name}
                      className={`pb-4`}
                    >
                      <div className="border-b flex-shrink-0 flex h-full  flex-col hover:text-brand-500 transition-shadow relative text-black hover:text-gray-800 focus:ring-brand-500 focus:border-brand-500 ">
                        <Link
                          to={"/" + post.city.Slug + "/" + post.service.Slug + "/"}
                          // className="hover:underline leading-none  focus:ring-brand-500 focus:border-brand-500 h-full"
                        >
                          <div>
                          <GatsbyImage
                            image={heroImage ? heroImage : placeHolderImageData}
                            className="w-full object-cover h-40"
                          />
                          </div>

      <div className=" p-1 py-4 font-medium leading-5 font-display">
                                  {post.service.Name}
                                </div>
                          
                        </Link>
                       
                      </div>
                    </div>
                  );
                })}{" "}
              </div>


          </div>
        </div>

      </section>





    </Layout>
  );
};

export default LocalServices;
